import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import GeneralQuery from '../queries/generalQuery'
import Header from './header'
import Footer from './footer'
import './layout.scss'
import 'prismjs/themes/prism.css'

const seoFields = ["metaDescription", "metaKeywords"]

const Layout = ({ children, title, ...meta }) => {
  seoFields.forEach(field => {
    if (meta[field] === undefined) {
      throw new Error(`Non-existing field: ${field}`)
    }
  })
  return (
  <GeneralQuery
    render={data => {
      const siteUrl = data.site.siteMetadata.siteUrl;
      const metaTitle = title ? title : data.site.siteMetadata.title
      const metaDescription = meta.metaDescription || 'Advanced software development, functional programming, advanced technology'
      const metaKeywords = meta.metaKeywords || 'lisp, haskell, functional programming, software development'
      const metaUrl = function () {
        const url = meta.metaUrl || siteUrl;
        const start = url.startsWith("http") ? "" : siteUrl;
        const end = url.endsWith("/") ? "" : "/";
        return start + url + end;
      }();
      return <>
        <Helmet
          title={metaTitle}
          meta={[
            { name: 'description',
              content: metaDescription },
            { name: 'keywords',
              content: metaKeywords },
            { name: 'twitter:card',
              content: 'summary' },
            { name: 'twitter:site',
              content: '@jav_casas' },
            { name: 'twitter:title',
              content: metaTitle },
            { name: 'twitter:description',
              content: metaDescription },
            { property: 'og:title',
              content: metaTitle },
            { property: 'og:description',
              content: metaDescription },
            { property: 'og:url',
              content: metaUrl },
          ]}
        >
          <html lang="en" />
          <script>
            var clicky_site_ids = clicky_site_ids || [];
            clicky_site_ids.push(101172085);
          </script>
          <script async src="//static.getclicky.com/js" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div
          style={{
            margin: '0 auto',
            maxWidth: 960,
            padding: '0px 1.0875rem 1.45rem',
            paddingTop: 0
          }}
        >
          {children}
        </div>
        <Footer />
      </>
    }}
  />
)}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
