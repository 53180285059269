import React from 'react'
import { Link } from 'gatsby'

const Title = ({ siteTitle, subtitle }) => (
  <div
    style={
      {
        //background: 'darkslateblue',
      }
    }
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '1.45rem 1.0875rem',
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: '#111',
            textDecoration: 'none',
            fontWeight: '100',
            fontFamily: 'helvetica',
            fontSize: '150%',
            padding: '0 0px',
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <h2 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: '#333',
            textDecoration: 'none',
            fontWeight: '100',
            fontFamily: 'helvetica',
            fontSize: '90%',
          }}
        >
          {subtitle}
        </Link>
      </h2>
    </div>
  </div>
)

const Menu = ({ children }) => (
  <nav
    style={{
      margin: '0 20px 30px 20px',
      borderStyle: 'solid',
      borderWidth: '1px 0 1px 0',
      borderColor: '#999',
    }}
  >
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
      }}
    >
      {children}
    </div>
  </nav>
)

const MenuItem = ({ destination, children }) => (
  <Link
    to={destination}
    style={{
      display: 'inline-block',
      color: 'black',
      textDecoration: 'none',
      fontWeight: '100',
      fontFamily: 'helvetica',
      padding: '20px',
      margin: '0px 5px',
    }}
  >
    {children}
  </Link>
)

const Header = ({ siteTitle }) => (
  <>
    <header>
      <Title
        siteTitle={siteTitle}
        subtitle="A random walk through computer science"
      />
    </header>
    <Menu>
      <MenuItem destination="/">Blog Index</MenuItem>
      <MenuItem destination="/portfolio">Portfolio</MenuItem>
      <MenuItem destination="/cv">CV</MenuItem>
      <MenuItem destination="/about">About</MenuItem>
      <MenuItem destination="/contact">Contact</MenuItem>
    </Menu>
  </>
)

export default Header
