import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const query = graphql`
  query GeneralQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allFile {
      edges {
        node {
          childJavascriptFrontmatter {
            frontmatter {
              title
              date
              series
              error
            }
          }
          absolutePath
          relativePath
        }
      }
    }
  }
`

const sq = ({ render }) => <StaticQuery query={query} render={render} />

sq.propTypes = {
  render: PropTypes.func.isRequired,
}

export default sq
