import React from 'react'

const Footer = ({ siteTitle }) => (
  <footer>
    <div
      style={{
        margin: '30px 20px 30px 20px',
        borderStyle: 'solid',
        borderWidth: '1px 0 0 0',
        borderColor: '#999',
        textAlign: 'right',
        fontSize: '80%',
      }}
    >
      Copyright &copy; 2018-2023 Javier Casas - All rights reserved.
    </div>
  </footer>
)

export default Footer
